import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Countdown from 'react-countdown'
import axios from 'axios'


import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react'
import { configureChains, createConfig, useAccount, useConnect, useDisconnect, useEnsAvatar, useEnsName, usePrepareSendTransaction, useQueryClient, useSendTransaction, useWaitForTransaction, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import { useDebounce } from 'use-debounce'
import { parseEther } from 'viem'
import { utils } from 'ethers'
import { Link } from 'react-router-dom'
// import { InjectedConnector } from 'wagmi/connectors/injected'


import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm'




const SeedSale = () => {
    
    const [step, setStep] = useState(1)
    const [wallet, setWallet] = useState(1)
    
    
    const { open } = useWeb3Modal();
    
    
    const clickConnect = () => {
        open("ConnectWallet")
    }
    
    const chains = [arbitrum, mainnet, polygon]
    const projectId = 'e1fbab891c9e0c058e99e6f3ed1ec10d'
    
    const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

    const wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, version: 1, chains }),
      publicClient
    })
    
    const ethereumClient = new EthereumClient(wagmiConfig, chains)

    const { address, connector, isConnected } = useAccount()

    const { data: ensAvatar } = useEnsAvatar({ address })

    useEffect(() => {
        // isConnected && console.log(address)
    }, [isConnected])

    const [to, setTo] = useState('0xff5aFB0d7b2AE6D6a0Fdadb56cD28ADC454d18a8')
    const [debouncedTo] = useDebounce(to, 500)
    
    const [amount, setAmount] = useState('0.05')
    const [debouncedAmount] = useDebounce(amount, 500)
    
    const { config } = usePrepareSendTransaction({
        request: {
        to: debouncedTo,
        value: debouncedAmount ? utils.parseEther(debouncedAmount) : undefined,
        },
    })
    const { data, sendTransaction } = useSendTransaction(config)

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

    

    // // useEffect(() => {
    //     isLoading && console.log('loading')
    //     isSuccess && console.log('Successsss')
    // // }, [isSuccess, isLoading])

    
    // const connectWallet = () => {
    //     if (window.ethereum) {
    //         window.ethereum.request({method: 'eth_requestAccounts'})
    //         .then(result => {
    //             accountChangedHandler(result[0])
    //         })
    //     } else {
    //         alert('Extension not installed')
    //     }
    // }

    // const accountChangedHandler = (newAccount) => {
    //     alert(`Account address: ${newAccount}`)
    // }


    // CURRENCY CONVERSION
    const [rate, setRate] = useState(0)
    const [usd, setUsd] = useState('')
    const [eth, setEth] = useState('')

    const options = {method: 'GET', headers: {accept: 'application/json'}};

    useEffect(() => {
        fetch('https://api.fastforex.io/convert?from=ETH&to=USD&amount=2&api_key=a0deca88dc-025f88695e-rwnbkl', options)
        .then(response => response.json())
        .then(response => setRate(response.result.rate))
        .catch(err => console.error(err))
    }, [])

    const usdToEth = (val) => {
        setUsd(val)
        setEth((val / rate).toFixed(2))
    }
    const ethToUsd = (val) => {
        setEth(val)
        setUsd((val * rate).toFixed(2))
    }




    const stripePromise = loadStripe('pk_live_8n0WXYhsmzfpfmx1WR10hRZ7');
    // const stripePromise = loadStripe('pk_test_64wA2Up6Mwy1wLdmIpT7Zeaj');
    // const stripePromise = loadStripe('pk_test_51NJCxOIgw2JjfLhRIrioNfAneebi4FdLwmPD8V7gR15YYK9n61fP8sq9fTYMSoqyxs1sLogQS1oCgjik2unvcUeb00RPfUpFZE');

    const [checkout, setCheckout] = useState(1)
    


    

    const [cryptoBtn, setCryptoBtn] = useState('Cryptocurrency (Coinbase)')

    const cryptoPay = () => {
        setCryptoBtn('Please Wait...')
        axios.post('https://londongold-backend.vercel.app/checkout', {
            amount: parseInt(usd),
            currency: 'USD'
        })
        .then(res => {
            var hostedLink = document.createElement('a')
            hostedLink.href = res.data.charge.hosted_url
            hostedLink.target = 'blank'
            hostedLink.click()
        })
        .catch(err => console.log(err))
    }



  return (
    <>
        {/* <WagmiConfig config={wagmiConfig}> */}
            <Header />

            <br />

            <section className="py-5">

                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-7 mt-4">
                            <h1 className="section-headline mb-4">Seed Sale</h1>
                            <p className='w-100 mb-3'>
                                Welcome to the Seed Sale page, where you can learn all about our exciting token offering. During this limited-time sale, you have the opportunity to secure a stake in our groundbreaking project.
                            </p>
                            <a href="../../whitepaper.pdf" target='blank' className='primary-btn'>
                                <img src="../../icons/paper.png" alt="" />
                                Whitepaper
                            </a>
                        </div>
                        <div className="col-lg-5 mt-4">
                            {
                                checkout === 1 && (
                                    <>
                                    
                                        <h3 className='mb-2'>Stake</h3>
                                        <h5>Grow Your Crypto Holdings with Staking</h5>
                                        <div className="stake-container">
                                            <Countdown className='sale-countdown' date={'6/29/2023'} />
                                            <div className="total-staked">
                                                <h6>Total Staked</h6>
                                                <span className='fs-5'>23,123,923.93</span>
                                            </div>
                                            <h6 className='mt-4 fs-9'>Amount in USD you pay</h6>
                                            <div className="stake-field mt-2">
                                                <input type="text" value={usd} onChange={(e) => usdToEth(e.target.value)} placeholder='0' />
                                                <div className='stake-currency'>USD</div>
                                                {/* <div className="dropdown">
                                                    <button className='primary-btn' data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="../../icons/ethereum.png" className='drop-curr' alt="" />
                                                        ETH
                                                        <img src="../../icons/down.png" className='drop-down' alt="" />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <img src="../../icons/ethereum.png" alt="" />
                                                            ETH
                                                        </li>
                                                        <li>
                                                            <img src="../../icons/bitcoin.png" alt="" />
                                                            BTC
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <h6 className='mt-3 fs-9'>Amount in ETH you receive</h6>
                                            <div className="stake-field mt-2">
                                                <input type="text" value={eth} onChange={(e) => ethToUsd(e.target.value)} placeholder='0' />
                                                <div className='stake-currency'>ETH</div>
                                            </div>
                                            {/* <Web3Button ref={connectBtn} /> */}
                                            {
                                                !isConnected && <button className="primary-btn w-100 mt-3" onClick={clickConnect}>Connect Wallet</button>
                                            }
                                            {
                                                isConnected && (
                                                    <>
                                                        <button disabled={!usd || !eth ? true : false} className="outline-btn w-100 mt-3 fs-9" onClick={cryptoPay}>{cryptoBtn}</button>
                                                        <button disabled={!usd || !eth ? true : false} className="primary-btn w-100 mt-3 fs-9" onClick={() => setCheckout(2)}>Debit or Credit card</button>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </>
                                )
                            }
                            {
                                checkout === 2 && (
                                    <Elements stripe={stripePromise}>
                                        <img 
                                            src="../../icons/left.svg" 
                                            className='checkout-back' 
                                            onClick={() => setCheckout(1)} 
                                            alt="" 
                                        />
                                        <h5 className='d-flex justify-content-between mb-5'>
                                            <span className='text-primary'>{usd} USD</span>
                                            <span>{eth} ETH</span>
                                        </h5>
                                        <CheckoutForm eth={eth} usd={usd} />
                                    </Elements>
                                ) 
                            } 
                            {/* {
                                checkout === 3 && (
                                    <>
                                        <img 
                                            src="../../icons/left.svg" 
                                            className='checkout-back' 
                                            onClick={() => setCheckout(1)} 
                                            alt="" 
                                        />
                                        <div className="checkout-success">
                                            <img src="../../icons/done.svg" alt="" />
                                            <h6>
                                                Transaction successful! You have purchased <span className='text-primary'>{eth} ETH</span> worth of crypto tokens. Thank you for choosing our platform!
                                            </h6>
                                        </div>
                                    </>
                                ) 
                            }  */}
                        </div>
                    </div>
                </div>

            </section>



            <br />
            

            <Footer />


            <div class="modal fade" id="walletModal" tabindex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            {
                                step === 1 && (
                                    <>
                                        <h5>Connect Wallet</h5>                    
                                        <div className="row mt-2">
                                            <div className="col-md-6 mt-3">
                                                <div className={`wallet-item ${wallet === 1 && 'active'}`} onClick={() => setWallet(1)}>
                                                    <img src="../../img/metamask.png" alt="" />
                                                    Metamask
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 mt-3">
                                                <div className={`wallet-item ${wallet === 2 && 'active'}`} onClick={() => setWallet(2)}>
                                                    <img src="../../img/trust-wallet.png" alt="" />
                                                    Trust Wallet
                                                </div>
                                            </div> */}
                                        </div>
                                        <div class="d-flex justify-content-end mt-5">
                                            <button type="button" class="outline-btn me-2" data-bs-dismiss="modal">Close</button>
                                            {/* <button type="button" class="primary-btn" onClick={connectWallet}>Next</button> */}
                                        </div>
                                    </>
                                )
                            }
                            {
                                step === 2 && (
                                    <>
                                        <h5>Staking Option</h5>  
                                        <div className="stake-field mt-4">
                                            <input type="text" placeholder='0' />
                                            <div className="dropdown">
                                                <button className='primary-btn' data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="../../icons/ethereum.png" className='drop-curr' alt="" />
                                                    ETH
                                                    <img src="../../icons/down.png" className='drop-down' alt="" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <img src="../../icons/ethereum.png" alt="" />
                                                        ETH
                                                    </li>
                                                    <li>
                                                        <img src="../../icons/bitcoin.png" alt="" />
                                                        BTC
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="d-flex justify-content-end mt-5">
                                            <button type="button" class="outline-btn me-2" onClick={() => setStep(1)}>Previous</button>
                                            <button type="button" class="primary-btn" data-bs-dismiss="modal">Confirm</button>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        {/* </WagmiConfig> */}
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  )
}

export default SeedSale