import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

    const [header, setHeader] = useState(false)
    
  return (
    <div className='header'>
        <div className="container">
            <Link to={'/'} className='header-logo'>
                <img src="../../img/logo.svg" alt="" />
            </Link>
            <div className={`menu-toggle ${header && 'active'}`} onClick={() => setHeader(!header)}>
                <div className="line1"></div>
                <div className="line2"></div>
            </div>
            <ul className={`header-links ${header && 'active'}`}>
                <li className='header-dropdown'>
                    <Link to={'#'} className='header-dropdown-title'>
                        About Us
                        <img src="../../icons/down.png" alt="" />
                    </Link>
                    <ul className='header-dropdown-list'>
                        <li><Link to={'#'}>Documentation</Link></li>
                        <li><a href={'http://www.definewsnetwork.com/'} target='blank'>Blog</a></li>
                        <li><Link to={'/faq'}>FAQ</Link></li>
                        <li><a href={'https://www.blockready.com/a/2147490076/5aAS7zPa'} target='blank'>Learn</a></li>
                    </ul>
                </li>
                <li className='header-dropdown'>
                    <Link to={'#'} className='header-dropdown-title'>
                        Developer
                        <img src="../../icons/down.png" alt="" />
                    </Link>
                    <ul className='header-dropdown-list'>
                        <li><Link to={'#'}>London Gold Whitepaper</Link></li>
                        <li><Link to={'#'}>Smart Contract</Link></li>
                        <li><Link to={'#'}>Tokenomics</Link></li>
                        {/* <li><a href={'../../whitepaper.pdf'} target='blank'>London Gold Whitepaper</a></li>
                        <li><a href={'https://etherscan.io/token/0xb3c28f8b7929497285d07a8f48ff466a7d4c782f'} target='blank'>Smart Contract</a></li>
                        <li><a href={'../../whitepaper.pdf#page=10'} target='blank'>Tokenomics</a></li> */}
                    </ul>
                </li>
                <li className='header-dropdown'>
                    <Link to={'#'} className='header-dropdown-title'>
                        Community
                        <img src="../../icons/down.png" alt="" />
                    </Link>
                    <ul className='header-dropdown-list'>
                        <li><a href={'https://twitter.com/londongoldinfo'} target='blank'>Twitter</a></li>
                        <li><a href={'https://medium.com/@londongold'} target='blank'>Medium</a></li>
                        <li><a href={'https://t.me/londongoldinfo'} target='blank'>Telegram Chat</a></li>
                        <li><a href={'https://t.me/londongoldnews'} target='blank'>Telegram Channel</a></li>
                    </ul>
                </li>
                <li className='header-dropdown'>
                    <Link to={'#'} className='header-dropdown-title'>
                        Careers
                        <img src="../../icons/down.png" alt="" />
                    </Link>
                    <ul className='header-dropdown-list'>
                        <li><Link to={'/careers'}>Positions Available</Link></li>
                        <li><a href={'mailto:contact@londongold.com'}>Contact Us</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Header