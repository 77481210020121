import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
        <div className="container my-5">
            <div className="row">
                <div className="col-lg-4 mt-3">
                    <Link to={'/'} className='footer-logo'>
                        <img src="../../img/logo.svg" alt="" />
                        <p className='footer-copy'>
                            © Copyright 2023 <br /> All Rights Reserved by <Link to={'/'}>London Gold</Link>
                        </p>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mt-3">
                    <h6 className='footer-heading'>About Us</h6>
                    <ul className='footer-list'>
                        <li><Link to={'#'}>Documentation</Link></li>
                        <li><a href={'http://www.definewsnetwork.com/'} target='blank'>Blog</a></li>
                        <li><Link to={'/faq'}>FAQ</Link></li>
                        <li><a href={'https://www.blockready.com/a/2147490076/5aAS7zPa'} target='blank'>Learn</a></li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mt-3">
                    <h6 className='footer-heading'>developer</h6>
                    <ul className='footer-list'>
                        <li><Link to={'#'}>London Gold Whitepaper</Link></li>
                        <li><Link to={'#'}>Smart Contract</Link></li>
                        <li><Link to={'#'}>Tokenomics</Link></li>
                        {/* <li><a href={'../../whitepaper.pdf'} target='blank'>London Gold Whitepaper</a></li>
                        <li><a href={'https://etherscan.io/token/0xb3c28f8b7929497285d07a8f48ff466a7d4c782f'} target='blank'>Smart Contract</a></li>
                        <li><a href={'../../whitepaper.pdf#page=10'} target='blank'>Tokenomics</a></li> */}
                    </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mt-3">
                    <h6 className='footer-heading'>Community</h6>
                    <ul className='footer-list'>
                        <li><a href={'https://twitter.com/londongoldinfo'} target='blank'>Twitter</a></li>
                        <li><a href={'https://medium.com/@londongold'} target='blank'>Medium</a></li>
                        <li><a href={'https://t.me/londongoldinfo'} target='blank'>Telegram Chat</a></li>
                        <li><a href={'https://t.me/londongoldnews'} target='blank'>Telegram Channel</a></li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mt-3">
                    <h6 className='footer-heading'>Careers</h6>
                    <ul className='footer-list'>
                        <li><Link to={'/careers'}>Positions Available</Link></li>
                        <li><a href={'mailto:contact@londongold.com'}>Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer