import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'
import Footer from '../components/Footer'
import { TradingViewStockChartWidget } from 'react-tradingview-components'

const Home = () => {

  return (
    <div>
        
        <Header />

        <div className="banner">

            <div className="container">
                <div className="row">

                    <div className="col-md-6 banner-text">
                        <FadeIn>
                            <h1 delay={100}>Unlock the Power of Digital Gold with London Gold™</h1>
                            <h3 delay={1000}>Your Gateway to Decentralized Finance</h3>
                            <div delay={2000} className="d-flex flex-wrap mt-3">
                                <Link to={'/seedsale'} className='primary-btn mt-2 me-3'>Invest Now</Link>
                                <Link to={'#'} className='outline-btn mt-2'>
                                    Documentation
                                </Link>
                            </div>
                        </FadeIn>
                    </div>
                    <div 
                        className="col-md-6 h-100 d-none d-md-block text-center banner-img"
                    >
                        <img 
                            src="../../img/banner.png" 
                            alt="" 
                            className='img-fluid'
                        />
                    </div>

                </div>
            </div>

        </div>


        <section className='py-5'>
            <div className="container my-5">
                <div className="row">

                    <div className="col-lg-6 mt-3 d-flex align-items-center">
                        <div className="about-text">
                            <h2>What is London Gold?</h2>
                            <p>
                                London Gold™ is an asset reward-based DeFi network built on the Ethereum and Arbitrum Blockchain, led by experienced professionals with innovative technologies and use cases to reward its loyal community. 
                            </p>
                            <p>
                                London Gold is a DeFi network that provides a user-friendly platform for investors to manage and discover new DeFi assets and technologies. We offer our investors a range of reward-bearing Ethereum assets, including physical gold-based rewards, NFTs, and yield-farming assets. 
                            </p>
                            <Link to={'/seedsale'} className='primary-btn mt-4'>Invest Now</Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 mt-3 d-none d-lg-flex align-items-center justify-content-ccenter">
                        <img src="../../img/about.png" className='about-img' alt="" />
                    </div>

                </div>
            </div>
        </section>

        <section className="py-5 benefits">

            <div className="container my-5">

                <h2 className='mb-4 text-center section-headline'>Track Gold Prices</h2>
                <div className="head-sep mb-4"></div>

                <div className="row">
                    <div className="col-md-8 m-auto">
                        <TradingViewStockChartWidget
                            symbol='GOLD'
                            theme='Dark' 
                            range='2m'
                            width='100%'
                            height='400px'
                        />
                    </div>
                </div>
            </div>

        </section>

        <section className='value'>
            <div className="container py-5">
                <div className="col-md-10 m-auto">
                    <h2>How London Gold Offers Value?</h2>
                    <p>
                        London Gold™ is offering legitimate and trustworthy utility tools to help the community differentiate between fraudulent and legitimate projects.
                    </p>
                    <h2>Stability Mechanism</h2>
                    <p>
                        London Gold's core stability mechanism is built as an inflation hedge protocol. Our fixed supply ensures that it cannot be subject to the same inflationary pressures as traditional currencies.
                    </p>
                </div>
                <Link to={'/seedsale'} className='primary-btn mt-3 m-auto'>Invest Now</Link>
            </div>
        </section>

        <section className="py-5 benefits">

            <div className="container my-5">

                <h2 className='mb-4 text-center section-headline'>London Gold Key Benefits</h2>
                <div className="head-sep mb-4"></div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="benefit-card">
                            <div className="benefit-img">
                                <img src="../../icons/ben1.png" alt="" />
                            </div>
                            <h5>Programmable Smart Contract</h5>
                            <p>
                                London Gold's smart contract allows for easy trading and innovative DeFi functionalities
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="benefit-card">
                            <div className="benefit-img">
                                <img src="../../icons/ben2.png" alt="" />
                            </div>
                            <h5>Decentralized Protocol</h5>
                            <p>
                                London Gold operates on an open blockchain, ensuring no single entity or group controls access to the asset or the entire market
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="benefit-card">
                            <div className="benefit-img">
                                <img src="../../icons/ben3.png" alt="" />
                            </div>
                            <h5>Easy to Trade</h5>
                            <p>
                                London Gold can be easily traded against fiat and digital assets on various exchanges
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="benefit-card">
                            <div className="benefit-img">
                                <img src="../../icons/ben4.png" alt="" />
                            </div>
                            <h5>Low Fees</h5>
                            <p>
                                London Gold has low fees, making it more attractive for investment compared to other DeFi products
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="benefit-card">
                            <div className="benefit-img">
                                <img src="../../icons/ben5.png" alt="" />
                            </div>
                            <h5>Global Availability</h5>
                            <p>
                                London Gold has a low investment entry and is available to anyone worldwide
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="benefit-card">
                            <div className="benefit-img">
                                <img src="../../icons/ben6.png" alt="" />
                            </div>
                            <h5>Redeemable Rewards</h5>
                            <p>
                                London Gold rewards can be redeemed in physical allocated gold, unallocated gold, or fiat currency through a network of gold retailers
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section className="py-5">

            <div className="container my-5">

                <h2 className='section-headline mb-4 text-center'>Development Roadmap</h2>
                <div className="head-sep mb-5"></div>

                <div className="row">

                    <div className="col-md-4 mt-4">
                        <div className="roadmap-card">
                            <h2>Q3 - 2022</h2>
                            <ul>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    London Gold Idealization
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4">
                        <div className="roadmap-card">
                            <h2>Q1 - 2023</h2>
                            <ul>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Develop London Gold Communities
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Blockchain & Development Integration
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Partnership Onboarding
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    R&D Budget Initiation
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Development and Rollout Strategy
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4">
                        <div className="roadmap-card">
                            <h2>Q2 - 2023</h2>
                            <ul>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Audit
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Organize Partnerships
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Full-Scale Marketing Raid
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Seed Sale
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Final Presale
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    XAU2 Token Launch
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    DEX Listing
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Community Expansion
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Team & Infrastructure Expansion
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4">
                        <div className="roadmap-card">
                            <h2>Q3 - 2023</h2>
                            <ul>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    dApp Releases
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Continued Aggressive Marketing Push
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    dApp Marketing Partnership Initiated
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Adding more London Gold Team Members To Enhance Community Platforms 
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Introduction to the NFT Platform
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Continue to build partnerships with CEX, Launchpads, and DEXs in the Ethereum Ecosystem
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Bridge to Matic and Bridge to Arbitrum
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4">
                        <div className="roadmap-card">
                            <h2>Q4 - 2023</h2>
                            <ul>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Expand the London Gold Ecosystem
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    YouTube Influencers, Twitter, Google, TikTok, and Facebook, massive marketing push to start branding London Gold Developments
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Bridge to Base
                                </li>
                                <li>
                                    <img src="../../icons/right.png" alt="" />
                                    Development of Public Goods
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>

        </section>

        <section className="py-5">
            <div className="container">
                <div className="col-md-8 m-auto">
                    <div className="visit-blog">
                        <h2>Stay Informed</h2>
                        <h4>Visit Our Blog</h4>
                        <a href="http://www.definewsnetwork.com" target='blank' className='primary-btn'>Browse Blog</a>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5">

            <div className="container my-5">
                <h2 className="section-headline text-center mb-4">London Gold is Powered by</h2>
                <div className="head-sep mb-5"></div>
                <div className="row">
                    <div className="col-md-3 mt-4">
                        <a href="https://www.certik.com" target='blank' className='partner-img'>
                            <img src="../../img/partner1.png" className='img-fluid' alt="" />
                        </a>
                    </div>
                    <div className="col-md-3 mt-4">
                        <a href="https://brewlabs.info" target='blank' className='partner-img'>
                            <img src="../../img/partner1.svg" className='img-fluid' alt="" />
                        </a>
                    </div>
                    <div className="col-md-3 mt-4">
                        <a href="https://www.visa.com" target='blank' className='partner-img'>
                            <img src="../../img/partner4.svg" className='img-fluid' alt="" />
                        </a>
                    </div>
                    <div className="col-md-3 mt-4">
                        <a href="https://www.tokensuite.io" target='blank' className='partner-img'>
                            <img src="../../img/partner2.svg" className='img-fluid' alt="" />
                        </a>
                    </div>
                </div>
            </div>

        </section>

        <section className="py-5">

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mt-3 d-flex align-items-center justify-content-center join-img">
                        <img src="../../img/join-us.png" className='img-fluid' alt="" />
                    </div>

                    <div className="col-lg-6 mt-3 d-flex align-items-center">
                        <div className="join-text">
                            <h2>Join us for limitless possibilities</h2>
                            <p>
                                Join our social community and dive into a world of limitless possibilities. Stay up-to-date with the latest project updates, connect with like-minded individuals, and explore innovative ideas shaping our future
                            </p>
                            <div className="join-cta">
                                <a href="https://linktr.ee/londongoldinfo" target='blank' className='primary-btn'>Join Socials</a>
                                <a href="https://medium.com/@londongold" target='blank' className='join-social'>
                                    <img src="../../icons/medium.png" alt="" />
                                </a>
                                <a href="https://twitter.com/londongoldinfo" target='blank' className='join-social'>
                                    <img src="../../icons/twitter.png" alt="" />
                                </a>
                                <a href="https://t.me/londongoldinfo" target='blank' className='join-social'>
                                    <img src="../../icons/telegram.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>


        <Footer />

    </div>
  )
}

export default Home