import React, { useRef } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Careers = () => {

    const ref1 = useRef(null)
    const ref2 = useRef(null)

    const clickFile1 = () => {
        ref1.current.click()
    }
    const clickFile2 = () => {
        ref1.current.click()
    }

  return (
    <div>
        <Header />

        <br />

        <section className="py-5">

            <div className="container my-5">
                <h1 className="section-headline mb-4 text-center">positions available</h1>
                <div className="head-sep mb-4"></div>
            </div>

        </section>

        <section className="pb-5">
            <div className="container mb-5">
                
                <h5 className='text-primary mb-4'>We are Hiring!</h5>

                <p className='mb-2'>
                    LONDON GOLD is building a blockchain project built with Ethereum Smart Contract logic enabling native Ethereum Investor communities the opportunity to participate within the Ethereum Blockchain ecosystem.
                </p>
                <p className='mb-2'>
                    If you want to help build our project and be part of a creative, high-performing, innovative and dedicated team join us building the future as we pave the way to change lives.
                </p>

                <h6 className='text-light my-4'>
                    Here are the team member opportunities currently available:
                </h6>

                <h5 className='text-primary'>LONDON GOLD Partnership Manager</h5>

                <div className="careers-list">
                    <h6>Responsibilities:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Understanding the needs of our investors
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Suggest how LONDON GOLD can meet these needs
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Actively do outreach to other Ethereum Blockchain projects to build strategic alliances for our investors
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Ability to lead the negotiation process from start to finish
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Ability to explain complex information in a way that is easily understandable
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Follow up with investors and partners resolving any issues that may arise
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Create proposals and reports related to investor onboarding, pitch materials and more
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Ensure all project milestones are consistently met
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Update records of investor communications and/or contact information
                        </li>
                    </ul>
                </div>
                <div className="careers-list">
                    <h6>Requirements:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            A bachelor’s degree in Business, IT or a related field
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            A minimum of 3 years of tech sales/SaaS experience is preferred
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Ability to quickly grasp technical knowledge of crypto products
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            A comprehensive understanding of crypto/Web 3 is required
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Strong interpersonal, communication and presentation skills
                        </li>
                    </ul>
                </div>

                <h5 className='text-primary mt-5'>LONDON GOLD Marketing Manager</h5>

                <div className="careers-list">
                    <h6>Responsibilities:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Create and execute marketing plans that drive awareness and understanding of LONDON GOLD, with a particular focus on our developments and our investors
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Provide cross channel marketing campaigns and engagement plans for LONDON GOLD, new project feature roll outs, events and evaluate project performance for further growth
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Manage marketing/growth campaigns calendar, budget, major announcements, conferences, podcasts, AMAs and other opportunities to achieve key results with a specific focus on community growth
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Plan and manage campaign execution with cross-functional members of the project, internal creative team, external agencies, partners and community content creators
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Ensure all project marketing materials align with brand positioning and tone of voice (website, blog, social media, community channels, etc.)
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Contribute across all social channels and rapid experimentation with new channels, campaigns, creatives, incentives, and approaches
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Measure, monitor and build project details identifying trends and opportunities for project improvement
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Build and maintain strong partnerships with key investors in the project
                        </li>
                    </ul>
                </div>
                <div className="careers-list">
                    <h6>Requirements:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Knowledge and interest in the world of Ethereum, web3 and the Ethereum Blockchain is a must
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            3 to 5 years of experience in brand management and growth marketing
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Interest and general knowledge of the cryptocurrency/blockchain industry
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            People and team management experience
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Strong interest in being able to drive foundational marketing strategies, but also to bring new ideas and tactics to the table
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Experience using evaluation and ROI tools to create daily/weekly reports for organizational leadership
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Attention to detail with superior organizational and communication skills
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Excellent project and time management skills; able to prioritize and work under pressure
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Native or fluent English speaker and writer
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Bachelor's degree or higher
                        </li>
                    </ul>
                </div>
                
                <h5 className='text-primary mt-5'>LONDON GOLD SEO Specialist</h5>

                <div className="careers-list">
                    <h6>Responsibilities:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Vendor management
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Investor management and relationship building - internally and externally
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Working collaboratively with Web, Development, Creative and Marketing teams
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Reporting on content effectiveness: monitor web traffic and user engagement (e.g. bounce rates)
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Ensuring timelines are managed effectively and consistently with all given projects and content workflows
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Maintaining best practices and developing online content standards, compliance, voice and tone, style guides
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Proofreading, restructuring and editing articles written by content writers
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Planning, creating, editing and publishing written content for different formats / multimedia / digital channels and platforms
                        </li>
                    </ul>
                </div>
                <div className="careers-list">
                    <h6>Requirements:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Proven work experience as a Content Editor, Web Editor or similar role
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            An understanding of the Ethereum Blockchain and experience navigating its ecosystem would be a huge advantage
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Portfolio of published work
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Excellent written and verbal communication skills
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Attention to detail
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Hands-on experience with Content Management Systems
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Familiarity with SEO and keyword research
                        </li>
                    </ul>
                </div>

                <h5 className='text-primary mt-5'>LONDON GOLD Designer</h5>

                <p className='mt-3'>
                    As the Designer on our team, you will work closely with our product manager, engineer, and business team to design, build, and enhance LONDON GOLD systems and data products for our crypto platform ecosystem in order to facilitate and maximize the effectiveness
                </p>

                <div className="careers-list">
                    <h6>Responsibilities:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Lead the design process for our platform products (mobile and web)
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Be responsible for the design delivery, but not limited to Trading-related functions across native mobile and web ends
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Define the design guideline to cultivate consistency for LONDON GOLD platform’s internal products and related services
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Define the visual and interaction methods for the LONDON GOLD platform products to cultivate a better experience for the LONDON GOLD platform products and related services
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Lead/participate in user and market research to produce product analysis and constructive improvement practices
                        </li>
                    </ul>
                </div>
                <div className="careers-list">
                    <h6>Requirements:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            3+ years of Web & Mobile UX/UI and logo design experience at a product-driven company and have related design experience in cryptocurrency, trading or fintech, and B2B experience would be a plus
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Sharp attention to detail and mastery over your all deliveries
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Sophisticated in Figma and its capabilities
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Understand how your design would work in both frontend and backend
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Work between multiple projects effectively while managing timelines and expectations
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Experience in working collaboratively with product management, engineering, design, and business teams
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Able to articulate goals and concepts succinctly and effectively to the broader product team and other internal stakeholders
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Strong communication skills in English to liaise with the investors
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Please provide your portfolio in addition to your resume. It can be an attached soft copy or an URL
                        </li>
                    </ul>
                </div>

                <h5 className='text-primary mt-5'>LONDON GOLD Technology Manager</h5>

                <div className="careers-list">
                    <h6>Responsibilities:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Work closely with the community, investors, and internal teams to shape the LONDON GOLD technology roadmap
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Lead developers to design, develop, test and deploy project technology infrastructure components with the best practices and technology excellence
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Drive innovation together with both internal and external talents
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Focus on user experience, stability, and scalability of the systems
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Hire and grow the best team
                        </li>
                    </ul>
                </div>
                <div className="careers-list">
                    <h6>Requirements:</h6>
                    <ul>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            5+ years of experience in the software industry
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            2+ years of team management experience
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Strong technical skills in distributed systems research and development, proficient in one or more programming languages, popular programming paradigms, frameworks, libraries, and middlewares
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Familiarity with the best practices of SDLC, especially on open source projects
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Specifically goal-driven and result-oriented; passionate, entrepreneurial, optimistic, and a natural team player.
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Strong communication and collaboration skills, experience in working across different departments, investors and amongst project/partner communities
                        </li>
                        <li>
                            <img src="../../icons/pointing-right.png" alt="" />
                            Business level English
                        </li>
                    </ul>
                </div>

            </div>
        </section>

        <section className="py-5">
            <div className="container mb-5">
                <h1 className="section-headline mb-4 text-center">APPLY FOR THIS JOB</h1>
                <div className="head-sep mb-4"></div>

                <div className="col-lg-8 col-md-10 m-auto">
                    <div className="row">
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>First Name</label>
                                <input type="text" className='form-field' placeholder='first name' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Last Name</label>
                                <input type="text" className='form-field' placeholder='last name' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Email</label>
                                <input type="email" className='form-field' placeholder='email' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Phone</label>
                                <input type="text" className='form-field' placeholder='phone' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Resume/CV</label>
                                <button className='form-upload' onClick={clickFile1}>
                                    <img src="../../icons/upload.png" alt="" />
                                    File types: pdf, doc, docx, txt, rtf
                                </button>
                                <input type="file" ref={ref1} hidden />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Cover Letter</label>
                                <button className='form-upload' onClick={clickFile2}>
                                    <img src="../../icons/upload.png" alt="" />
                                    File types: pdf, doc, docx, txt, rtf
                                </button>
                                <input type="file" ref={ref2} hidden />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>LinkedIn Profile</label>
                                <input type="text" className='form-field' placeholder='linkedin profile' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Website</label>
                                <input type="text" className='form-field' placeholder='website' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Twitter</label>
                                <input type="text" className='form-field' placeholder='twitter' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Telegram</label>
                                <input type="text" className='form-field' placeholder='telegram' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                <label className='form-label'>Discord</label>
                                <input type="text" className='form-field' placeholder='discord' />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-container">
                                {/* <label className='form-label'>Discord</label> */}
                                <button className='primary-btn submit-btn'>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <br />
        

        <Footer />
    </div>
  )
}

export default Careers