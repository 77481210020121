import './App.css';
import './responsive.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Leaderboard from './pages/Leaderboard';
import Staking from './pages/Staking';
import About from './pages/About';
import SeedSale from './pages/SeedSale';
import PrivateSale from './pages/PrivateSale';
import PublicSale from './pages/PublicSale';
import Faq from './pages/Faq';
import Careers from './pages/Careers';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react'
import { configureChains, createConfig, useAccount, useConnect, useDisconnect, useEnsName, usePrepareSendTransaction, useQueryClient, useSendTransaction, useWaitForTransaction, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

function App() {

  const chains = [arbitrum, mainnet, polygon]
  const projectId = 'e1fbab891c9e0c058e99e6f3ed1ec10d'
  
  const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    publicClient
  })

  return (
    <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/leaderboard' element={<Leaderboard />} />
          {/* <Route path='/staking' element={<Staking />} /> */}
          <Route path='/about' element={<About />} />
          <Route path='/seedSale' element={<SeedSale />} />
          <Route path='/privateSale' element={<PrivateSale />} />
          <Route path='/publicSale' element={<PublicSale />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/careers' element={<Careers />} />
        </Routes>
      </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;
