import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const About = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]
      };

  return (
    <div>
        <Header />

        <br />

        <section className="py-5">

            <div className="container my-5">
                <h1 className="section-headline mb-4 text-center">About us</h1>
                <div className="head-sep mb-5"></div>

                <div className="row">
                    <div className="col-lg-6 mt-4">
                        <img src="../../img/about.jpg" className='img-fluid rounded' alt="" />
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="about-text">
                            <h2>Our Mission</h2>
                            <p>
                                At London Gold, our mission is to provide a seamless and inclusive platform for buying Ethereum tokens. We believe in the transformative potential of blockchain technology and its ability to revolutionize the way we transact and interact with digital assets.  
                            </p>
                            <p>
                                With a user-friendly interface and secure infrastructure, we aim to democratize access to Ethereum tokens, enabling individuals from all backgrounds to participate in this exciting decentralized ecosystem.
                                Whether you're a seasoned crypto enthusiast or new to the world of digital currencies, our platform is designed to make buying Ethereum tokens simple, secure, and accessible to all. Join us on this journey as we pave the way for a future powered by Ethereum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section className="about-quote py-5">
            <div className="container my-4">
                <h1>"Empowering individuals through technology and innovation"</h1>
            </div>
        </section>

        <section className='py-5'>
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 mt-4">
                        <div className="about-text">
                            <h2>Our History</h2>
                            <p>
                                Since our inception in 2022, we have embarked on an incredible journey of growth and innovation. From humble beginnings to becoming a recognized leader in the industry, our history is filled with milestones and achievements that have shaped who we are today. We have overcome challenges, embraced change, and continually adapted to meet the evolving needs of our customers. Through dedication, perseverance, and a relentless pursuit of excellence, we have built a legacy of success that we are proud to share. Join us as we continue to make history and shape the future of London Gold.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <img src="../../img/history.jpg" className='img-fluid rounded history-img' alt="" />
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 team">
            <div className="container mb-5">
                <h2 className="section-headline mb-4 text-center">Meet the team</h2>
                <div className="head-sep mb-4"></div>
                <h6 className='text-center mb-5'>
                    The team at London Gold comprises experts inblockchain technology, finance, and business development, with years of experience working in the cryptocurrency industry and other related fields
                </h6>
                <br />
                <Slider {...settings}>
                    <div>
                        <div className="team-item">
                            <img src="../../img/team1.jpg" alt="" />
                            <h5>Digital</h5>
                            <h6>Senior Developer</h6>
                        </div>
                    </div>
                    <div>
                        <div className="team-item">
                            <img src="../../img/team5.jpg" alt="" />
                            <h5>Diana</h5>
                            <h6>CTO</h6>
                        </div>
                    </div>
                    <div>
                        <div className="team-item">
                            <img src="../../img/team3.jpg" alt="" />
                            <h5>Damian</h5>
                            <h6>Senior Developer</h6>
                        </div>
                    </div>
                    <div>
                        <div className="team-item">
                            <img src="../../img/team2.jpg" alt="" />
                            <h5>Maven</h5>
                            <h6>Blockchain Developer</h6>
                        </div>
                    </div>
                    <div>
                        <div className="team-item">
                            <img src="../../img/team4.jpg" alt="" />
                            <h5>Eli</h5>
                            <h6>Web Developer</h6>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default About