import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const PrivateSale = () => {

  return (
    <div>
        <Header />

        <br />

        <section className="py-5">

            <div className="container my-5">
                <h1 className="section-headline mb-4 text-center">Private Sale</h1>
                <div className="head-sep mb-5"></div>

                <div className="col-lg-10 m-auto">
                    <p className='text-center w-100 mb-3'>
                        Welcome to the Private Sale. This exclusive fundraising round offers a unique opportunity for a select group of investors to participate in the early stages of our project
                    </p>
                    
                </div>
            </div>

        </section>

        <section className="py-5">
            <div className="container mb-5">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Token Name</td>
                                <td>Token Symbol</td>
                                <td>Total Supply</td>
                                <td>Token Type</td>
                                <td>Sale Start Date</td>
                                <td>Sale End Date</td>
                                <td>Token Price</td>
                                <td>Minimum Investment</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>


        <br />
        

        <Footer />
    </div>
  )
}

export default PrivateSale