import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Leaderboard = () => {
  return (
    <div>
        <Header />

        <br />
        <section className='py-5'>
            <div className="container my-5">

                <h2 className='section-headline text-center mb-4'>Leaderboard</h2>
                <div className="head-sep mb-5"></div>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Rank</td>
                                <td>Username</td>
                                <td>Wallet Address</td>
                                <td>Token Balance</td>
                                <td>Total Supply %</td>
                                <td>Value</td>
                                <td>Location</td>
                                <td>Last Activity</td>
                                <td>Achievements</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>CryptoMaster</td>
                                <td>0x5F98456AbC1234Ef56789012345678901234567</td>
                                <td>1000</td>
                                <td>2.5%</td>
                                <td>$5000</td>
                                <td>United States</td>
                                <td>2023-04-28 14:30:00</td>
                                <td>Top Investor</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>CryptoMaster</td>
                                <td>0x5F98456AbC1234Ef56789012345678901234567</td>
                                <td>1000</td>
                                <td>2.5%</td>
                                <td>$5000</td>
                                <td>United States</td>
                                <td>2023-04-28 14:30:00</td>
                                <td>Top Investor</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>CryptoMaster</td>
                                <td>0x5F98456AbC1234Ef56789012345678901234567</td>
                                <td>1000</td>
                                <td>2.5%</td>
                                <td>$5000</td>
                                <td>United States</td>
                                <td>2023-04-28 14:30:00</td>
                                <td>Top Investor</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>CryptoMaster</td>
                                <td>0x5F98456AbC1234Ef56789012345678901234567</td>
                                <td>1000</td>
                                <td>2.5%</td>
                                <td>$5000</td>
                                <td>United States</td>
                                <td>2023-04-28 14:30:00</td>
                                <td>Top Investor</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>CryptoMaster</td>
                                <td>0x5F98456AbC1234Ef56789012345678901234567</td>
                                <td>1000</td>
                                <td>2.5%</td>
                                <td>$5000</td>
                                <td>United States</td>
                                <td>2023-04-28 14:30:00</td>
                                <td>Top Investor</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>CryptoMaster</td>
                                <td>0x5F98456AbC1234Ef56789012345678901234567</td>
                                <td>1000</td>
                                <td>2.5%</td>
                                <td>$5000</td>
                                <td>United States</td>
                                <td>2023-04-28 14:30:00</td>
                                <td>Top Investor</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default Leaderboard