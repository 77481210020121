import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const PublicSale = () => {

  return (
    <div>
        <Header />

        <br />

        <section className="py-5">

            <div className="container my-5">
                <h1 className="section-headline mb-4 text-center">Public Sale</h1>
                <div className="head-sep mb-5"></div>

                <div className="col-lg-10 m-auto">
                    <p className='text-center w-100 mb-3'>
                        Welcome to the Public Sale. We are excited to offer you the opportunity to participate in this phase of our token sale, where you can purchase tokens and join our growing community. 
                    </p>
                    
                </div>
            </div>

        </section>

        <section className="py-5">
            <div className="container mb-5">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Token Name</td>
                                <td>Token Symbol</td>
                                <td>Total Supply</td>
                                <td>Token Type</td>
                                <td>Sale Start Date</td>
                                <td>Sale End Date</td>
                                <td>Token Price</td>
                                <td>Minimum Investment</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                            <tr>
                                <td>ABC Token</td>
                                <td>ABC</td>
                                <td>1,000,000,000 ABC</td>
                                <td>ERC-20</td>
                                <td>October 1, 2023</td>
                                <td>October 31, 2023</td>
                                <td>0.10 USD</td>
                                <td>100 USD</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>


        <br />
        

        <Footer />
    </div>
  )
}

export default PublicSale