import React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const CheckoutForm = ({ eth, usd }) => {

    const btn = useRef()

    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {
                iconColor: '#ecc57c',
                color: '#fff',
                fontSize: '18px',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#eee',
                }
            }
        }
    }


    const [btnText, setBtnText] = useState('Pay')
    const [btnDisabled, setBtnDisable] = useState(false)

    const stripe = useStripe();
    const elements = useElements();
  
    const handleSubmit = async (event) => {
        event.preventDefault();

        setBtnText('Please Wait...')
    
        if (elements == null) {
            return;
        }
    
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        
        if (!error) {
            try {
                const {id} = paymentMethod
                const response = await axios.post('https://londongold-backend.vercel.app/payment', {
                    amount: parseInt(usd) * 100,
                    id
                })

                if (response.data.success) {
                    console.log("successfull payment")
                    btn.current.click()
                    setBtnText('Pay')
                    setBtnDisable(true)
                }
            } catch (error) {
                console.log('Error', error)
            }
        } else {
            console.log(error.message)
        }
    };

    useEffect(() => {
        console.log(usd)
    }, [])
  
    return (
        <>
            <form onSubmit={handleSubmit}>
                <CardElement options={CARD_OPTIONS} />
                <button className='primary-btn w-100 mt-4' type="submit" disabled={!stripe || !elements || btnDisabled}>
                {btnText}
                </button>
            </form>
        
            <button type="button" ref={btn} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>

            <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
                <div class="modal-dialog bg-black p-4">
                    <div className="d-flex justify-content-end">
                        <img 
                            src="../../icons/remove.svg" 
                            className='checkout-back' 
                            alt="" 
                            data-bs-dismiss="modal"
                        />
                    </div>
                    <div className="checkout-success">
                        <img src="../../icons/done.svg" alt="" />
                        <h6>
                            Transaction successful! You have purchased <span className='text-primary'>{eth} ETH</span> worth of crypto tokens. Thank you for choosing our platform!
                        </h6>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutForm