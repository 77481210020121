import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Faq = () => {

  return (
    <div>
        <Header />

        <br />

        <section className="py-5">

            <div className="container my-5">
                <h1 className="section-headline mb-4 text-center">FAQ</h1>
                <div className="head-sep mb-5"></div>
            </div>

        </section>

        <section className="pb-5">
            <div className="container mb-5">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#q1"
                                aria-expanded="true"
                                aria-controls="q1"
                            >
                                What is the London Gold Project? 
                            </button>
                        </h2>
                        <div
                            id="q1"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body">
                                London GoldTM is revolutionizing finance through multi-network collaborations, combining the stability of gold with crypto innovations on a massive global scale.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q2"
                            aria-expanded="false"
                            aria-controls="q2"
                        >
                           How many chains London Gold is available now?
                        </button>
                        </h2>
                        <div
                        id="q2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            London Gold is paving the way for a rewarding community on multiple blockchain.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q3"
                            aria-expanded="false"
                            aria-controls="q3"
                        >
                            Do you have partnerships?
                        </button>
                        </h2>
                        <div
                            id="q3"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            We have partnered with Token Suite & Brewlabs to bring cutting edge solutions to our community and many more to come in future.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q4"
                            aria-expanded="false"
                            aria-controls="q4"
                        >
                            Of all the Defi Platforms, why should I use London Gold Platform
                        </button>
                        </h2>
                        <div
                            id="q4"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            London Gold is the best platform for people who are looking for innovative Technology Development, Indices, sustainable asset management which has less risk, less Volatility, High Rewards, Smooth and Solid returns on investments in either market direction.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q5"
                            aria-expanded="false"
                            aria-controls="q5"
                        >
                            What is the Percentage of reward? and in which Currency?
                        </button>
                        </h2>
                        <div
                            id="q5"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            4% rewards go back to holders 
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q6"
                            aria-expanded="false"
                            aria-controls="q6"
                        >
                            What do you mean by Physical Gold Based rewards? Where can I exchange them to USD
                        </button>
                        </h2>
                        <div
                            id="q6"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            Token holders have the option to convert their tokens into actual gold. If an investor has coins that are equal to or exceed 1 kilogram in gold value, they may choose to relinquish their tokens in exchange for an equivalent amount of physical gold.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q7"
                            aria-expanded="false"
                            aria-controls="q7"
                        >
                            How are you determining the price of Physical Gold Based rewards?
                        </button>
                        </h2>
                        <div
                            id="q7"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            Rewards are valued at the current market rate for gold on the global markets.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q8"
                            aria-expanded="false"
                            aria-controls="q8"
                        >
                            Since Bitcoin is called Digital Gold? Why not introduce Bitcoin Based rewards?
                        </button>
                        </h2>
                        <div
                            id="q8"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            Because Bitcoin can be volatile, and its value can fluctuate rapidly versus the stability of real physical gold. Real physical is also hedged against inflation.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q9"
                            aria-expanded="false"
                            aria-controls="q9"
                        >
                            Does the project have backers currently?
                        </button>
                        </h2>
                        <div
                            id="q9"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            The devs are bootstrapping the build currently.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q10"
                            aria-expanded="false"
                            aria-controls="q10"
                        >
                            Have we conducted any Audit report yet?
                        </button>
                        </h2>
                        <div
                            id="q10"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            Contracts are Certik audited
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#q11"
                            aria-expanded="false"
                            aria-controls="q11"
                        >
                            What is the project driving factor against other similar projects?
                        </button>
                        </h2>
                        <div
                            id="q11"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                        >
                        <div className="accordion-body">
                            We're the 1st project in the space rewarding holders in digital and physical gold
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <br />
        

        <Footer />
    </div>
  )
}

export default Faq